import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Example from "../components/example"
import SEO from "../components/seo"

export const query = graphql`
  query {
    allExamplesJson(sort: {fields: talk_order}) {
        edges {
            node {
                title
                description
                project
                analysis
                query
                image_name
            }
        }
     }
    allFile(filter: {relativePath: {glob: "examples/*.png"}}) {
        edges {
          node {
            name
            childImageSharp {
                gatsbyImageData
            }
          }
        }
      }
  }
`

const ExamplesPage = ({data}) => {
  
  return(
  <Layout>
    <SEO title="Examples" />
    <h2>Examples</h2>
    {data.allExamplesJson.edges.map(edge => {
      const example = edge.node
      let pngFile = ""
      data.allFile.edges.forEach(pngEdge => {
        if (pngEdge.node.name === example.image_name){
          pngFile = pngEdge.node
        }
      })
      return (<Example key={example.title} example={example} imgFile={pngFile} />)
     })}
  </Layout>
)}

export default ExamplesPage
