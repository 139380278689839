import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image"


const Example = ({ example, imgFile }) => {
   const imageData = getImage(imgFile)

    return (
    <div
      style={{
        margin: `2em 0 5em`
      }}
    >
        <h3>{example.title}</h3>
        Project: {example.project}
        {example.description && <p>{example.description}</p>}
        <div
          style={{
              marginTop: `1em`,
              marginBottom: `1em`
          }}
        >
            <pre>{example.query}</pre>
        </div>
        {imageData && <GatsbyImage image={imageData} alt="" />}
        {example.analysis && <p>{example.analysis}</p>}
    </div>
)}

export default Example
